import {
  ACTIVE,
  CLIENT_METRICS,
  CLIENT_OWNER,
  COMPLETED,
  DYNAMIC,
  ESCALATED_ASSESSMENTS_VIEW,
  ESCALATED_DELIVERY_STATUS,
  EXECUTIVE_SUMMARY_METRICS,
  NO_VALUE,
  REMEDIATION,
  RESERVED,
  RESULTS_EVIDENCE_LINK_ENABLED,
  VENDOR_OWNER,
  VENODR_METRICS,
} from 'constants/constants';
import { isArray, isEmpty, isNil, isString, isUndefined, omit } from 'lodash';
import { convertToLocalDateTime, convertUTCDateToLocalDate } from './dateAndTimeUtils';
import { getPercentage } from './numberUtils';
import { format, parse, intlFormatDistance, formatDistance } from 'date-fns';
import { ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID, GAP_VALIDATION_AWAITING_VENDOR_RESPONSE, INHERENT_RISK_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE, SCOPING_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE, SECURITY_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE } from 'pages/AssessmentsOverview/constants';
import { escapeSpecialCharacters } from './specialCharactersUtils';

const VENDOR_METRICS_CRITERION = [
  {
    Field: 'Owner',
    Operator: 'in',
    Value: `${VENDOR_OWNER}`,
  },
  {
    Field: 'AssessmentDisplayStatus',
    Operator: 'in',
    Value: `${SCOPING_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE},${SECURITY_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE},${GAP_VALIDATION_AWAITING_VENDOR_RESPONSE},${INHERENT_RISK_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE}`
  }
];

const CLIENT_METRICS_CRITERION = [
  {
    Field: 'Owner',
    Operator: 'in',
    Value: `${CLIENT_OWNER}`,
  },
  {
    Field: 'AssessmentStage',
    Operator: 'in',
    Value: `${ACTIVE},${REMEDIATION}`,
  },
];

const EXECUTIVE_SUMMARY_METRICS_CRITERION = [
  {
    Field: 'DisplayResultAndEvidenceLinks',
    Operator: '=',
    Value: `${RESULTS_EVIDENCE_LINK_ENABLED}`,
  },
  {
    Field: 'AssessmentStage',
    Operator: 'in',
    Value: `${REMEDIATION},${COMPLETED}`,
  },
];

const ESCALATED_ASSESSMENTS_VIEW_CRITERION = [
  {
    Field: 'DeliveryStatus',
    Operator: '=',
    Value: `${ESCALATED_DELIVERY_STATUS}`,
  }
];

export const getModifiedList = (actualList, completedAssessmentsCount = 0) => {
  let list = actualList;
  if (!isEmpty(actualList) && actualList?.length > 0) {
    list = actualList?.map((it, index) => {
      let envList = [];
      if (!isNil(it?.EnvironmentList) && !isUndefined(it?.EnvironmentList)) {
        envList = it?.EnvironmentList?.map((env) => {
          const envName = env?.EnvironmentName?.replace(env?.VrasEnvironmentName, '');
          return {
            ...env,
            VrasEnvironmentName: isNil(env?.VrasEnvironmentName) ? env?.EnvironmentName?.split('-')[0] : env?.VrasEnvironmentName,
            EnvironmentName: envName?.split('-').length > 1 ? `${envName?.split('-')[1]}` : env?.EnvironmentName,
          };
        });
      }

      //Convert LastUpdated of portal 2.0 date format to legacy date format
      let parsedLastUpdated = format(parse(it?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');

      return {
        ...it,
        DisplayVendorPercentage: String(it?.DisplayVendorPercentage) === '1',
        DisplayResultAndEvidence: String(it?.DisplayResultAndEvidenceLinks) === '1',
        SeqNo:
          !isNil(completedAssessmentsCount) && Number(completedAssessmentsCount) > 0
            ? `${index + 1}/${completedAssessmentsCount}`
            : `${index + 1}/${actualList?.length}`,
        VendorProgressPercent:
          String(it?.DisplayVendorPercentage) === '1' && !isNil(it?.PortalId) ? getPercentage(it?.CountOfResponses, it?.CountOfQuestions) : 0,
        LastActionDate: convertToLocalDateTime(parsedLastUpdated, 'MM/dd/yyyy'),
        LastActionTime: convertToLocalDateTime(parsedLastUpdated, 'h:mm a'),
        LastStatusActivity:
          !isEmpty(it) && !isEmpty(parsedLastUpdated)
            ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
              ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
              : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
            : null,
        UpdateTimeStamp: format(parse(parsedLastUpdated, 'MM/dd/yyyy HH:mm:ss', new Date()), "yyyy-MM-dd'T'HH:mm:ss"),
        EnvironmentList: envList,
      };
    });
  }
  return list;
};

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (key === 'type') {
      if (isEmpty(searchData[key])) {
        return criterion;
      } else if (searchData[key] === CLIENT_METRICS) {
        CLIENT_METRICS_CRITERION.forEach((it) => criterion.push(it));
      } else if (searchData[key] === VENODR_METRICS) {
        VENDOR_METRICS_CRITERION.forEach((it) => criterion.push(it));
      } else if (searchData[key] === EXECUTIVE_SUMMARY_METRICS) {
        EXECUTIVE_SUMMARY_METRICS_CRITERION.forEach((it) => criterion.push(it));
      } else if (searchData[key] === ESCALATED_ASSESSMENTS_VIEW) {
        ESCALATED_ASSESSMENTS_VIEW_CRITERION.forEach((it) => criterion.push(it));
      }
    } else if (key !== 'type') {
      if (isString(searchData[key])) {
        criterion.push({
          Field: key,
          Operator: 'contains',
          Value: escapeSpecialCharacters(searchData[key]),
        });
      }
      if (isArray(searchData[key]) && searchData[key]?.length > 0) {
        criterion.push({
          Field: key,
          Operator: 'in',
          Value: searchData[key].join(','),
        });
      }
    }
  });
  return criterion;
};

export const getSort = (searchData = {}, sortModel) => {
  let sort = [];
  if (!isEmpty(searchData)) {
    if (searchData?.qType === VENODR_METRICS || searchData?.qType === CLIENT_METRICS || searchData?.qType === EXECUTIVE_SUMMARY_METRICS) {
      sort.push({
        field: 'LastUpdated',
        order: 'ASC',
      });
    }
  }
  if (sortModel && sortModel?.length > 0) {
    const updatedSort = getAssessmentSortBody(sortModel);
    updatedSort &&
      updatedSort.forEach((it) => {
        sort.push(it);
      });
  }
  return sort;
};

const getSearchIntent = (searchData) => {
  let intent = '';
  if (!isEmpty(searchData)) {
    if (searchData?.type == ESCALATED_ASSESSMENTS_VIEW) {
      intent = DYNAMIC;
    }
  }
  return intent;
}

export const getRemediationAssessmentListSearchBody = (searchData = {}) => {
  let criterion = [];
  let intent = '';
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  let sort = getSort(searchData);
  return {
    intent: intent,
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      "ClientAssessment",
      'IsEscalated',
      'LatestAssessmentActionLog',
      'IsExpedite',
      'IsPaused'
    ],
    criterion: criterion,
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
          field: 'LastUpdated',
          order: 'DESC',
        },
    ],
    page: 1,
    pageSize: 200,
  };
};

export const getActiveAssessmentListSearchBody = (searchData = {}) => {
  let criterion = [];
  let intent = '';
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  let sort = getSort(searchData);
  return {
    intent: intent,
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'ClientAssessment',
      'IsEscalated',
      'LatestAssessmentActionLog',
      'IsExpedite',
      'IsPaused'
    ],
    criterion: criterion,
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
          field: 'LastUpdated',
          order: 'DESC',
        },
    ],
    page: 1,
    pageSize: 200,
  };
};

export const getReservedAssessmentListSearchBody = (searchData = {}) => {
  let intent = '';
  let criterion = [
    {
      Field: 'AssessmentStage',
      Operator: '=',
      Value: RESERVED,
    },
  ];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  let sort = getSort(searchData);
  return {
    intent: intent,
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'ClientAssessment'
    ],
    criterion: criterion,
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
          field: 'AssessmentStartDate',
          order: 'DESC',
        },
    ],
    page: 1,
    pageSize: 200,
  };
};

export const getCompletedAssessmentListSearchBody = (searchData = {}) => {
  let intent = '';
  let criterion = [
    {
      Field: 'AssessmentStage',
      Operator: '=',
      Value: COMPLETED,
    },
  ];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  let sort = getSort(searchData);
  return {
    intent: intent,
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'CountOfCompletedAssessments',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'ClientAssessment',
      'IsEscalated',
      'AssessmentLabel',
    ],
    criterion: criterion,
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
          field: 'LastUpdated',
          order: 'DESC',
        },
    ],
    page: 1,
    pageSize: 100,
  };
};

const getAssessmentSortBody = (sortModel) => {
  let sort = [];
  sortModel?.forEach((item) => {
    let obj = {};
    if (item?.field === 'LastStatusActivity') {
      obj = {
        field: 'LastUpdated',
        order: item?.sort?.toUpperCase(),
      };
    } else {
      obj = {
        ...item,
        order: item?.sort?.toUpperCase(),
      };
    }
    const omittedData = omit(obj, 'sort');
    sort.push(omittedData);
  });

  return sort;
};

export const getAssessmentListBody = (searchData = {}, page, pageSize, sortModel) => {
  let criterion = [];
  let intent = '';
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  let sort = getSort(searchData, sortModel);
  return {
    intent: intent,
    fields: [
      'AssessmentId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStartDate',
      'DynVendorAccountId',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'ClientAssessment',
      'IsEscalated',
      'LatestAssessmentActionLog',
    ],
    criterion: criterion,
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
          field: 'LastUpdated',
          order: 'DESC',
        },
    ],
    page: page,
    pageSize: pageSize,
  };
};

export const getAssessmentListCountBody = (searchData = {}) => {
  let criterion = [];
  let intent = '';
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  return {
    intent: intent,
    fields: [
      'CountOfAssessment',
      'DeliveryStatus',
      'DeliveryStatusName',
      'AssessmentStage',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'AssessmentDisplayStatus',
      'AssessmentTypeId',
      'ClientAssessment'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRequestedAssessmentSearchBody = (searchData = {}) => {
  let criterion = [];
  let intent = '';
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
    intent = getSearchIntent(searchData);
  }
  return {
    intent: intent,
    fields: [
      'AssessmentRequestId',
      'AssessmentRequestTypeId',
      'VendorOrgId',
      'VendorOrgName',
      'ClientId',
      'ProductName',
      'ProductType',
      'ProductCategory',
      'VendorId',
      'VendorName',
      'InsertUserId',
      'InsertUserName',
      'InsertTimestamp',
      'AssessmentQueue', // Field to indentify request from Assessment Queue page.
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentConfigurationBody = () => {
  return {
    intent: '',
    fields: ['MasterStepName', 'AssessmentDisplayStatus', 'Status', 'AssessmentDisplayStatusDropDown'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentNotesBody = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    intent: '',
    fields: ['StartDate', 'NewActionDetailsComments', 'NoteComments', 'Name', 'ModifiedOn', 'MaxLastUpdated'],
    Criterion: criterion,
    sort: [
      {
        field: 'CreatedOn',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentByIdBody = (id) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'LastUpdated',
      'AssessmentStatus',
      'AssessmentStage',
      'AssessmentTypeId',
      'MasterStepName',
      'AssessmentStartDate',
      'DynVendorAccountId',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'PortalId',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'Source',
      "ClientName",
      "VendorOrgId",
      'VendorNameForCustomer',
      "AssessmentCompletedDate"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getStatusHistory = id => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];
  return {
    intent: '',
    fields: [
      'ResponseId',
      'CreatedDate',
      'LastUpdated',
      'StatusName',
      'MasterStep',
      'AssessmentDisplayStatus',
      'AssessmentStatusName',
      'EnvironmentId',
      'MaxLastUpdated'
    ],
    criterion: criterion,
    sort: [
      {
        'Field': 'CreatedDate',
        'order': 'DESC'
      }
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getStatusHistoryForPortal = id => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];
  return {
    intent: '',
    fields: [
      'ResponseId',
      'CreatedDate',
      'LastUpdated',
      'EnvironmentId',
      'MaxLastUpdated',
      'ReviewComment',
      'AssessmentStatus',
      'AssessmentDisplayStatus'
    ],
    criterion: criterion,
    sort: [
      {
        'Field': 'CreatedDate',
        'order': 'DESC'
      }
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getQuestionnaireInfoBody = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: [
      'ClientOrgId',
      'AssessmentName',
      'AssessmentRequestId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'LastUpdated',
      "Source"
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getQuestionnaireInfoBodyForAssessment = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: [
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'LastUpdated',
      "CountOfMandatoryQuestions",
      "CountOfMandatoryAnswers",
      "QuestionnaireTemplateId",
      "QuestionnaireTemplateType",
      "AssessmentStatus",
      "QuestionnaireTemplateTypeId",
      "QuestionnaireTemplateName",
      "QuestionnaireTemplateStatus",
      "CountOfRequirement",
      "CountOfSubmittedRequirement",
      "AssessmentQuestionnaireId",
      "Source",
      "QuestionnaireTemplateSubType"
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getQueuedAssessmentsSearchBody = (page, pageSize) => {
  return {
    "Intent": "",
    "Fields": [

      "OrderNo",
      "QueuedAssessment",
      "VendorName",
      "ProductId",
      "ProductName",
      "ProductCategoryName",
      "AssessmentType",
      "ClientId",
      "AssessmentId",
      "VendorId",
      "Source"
    ],
    "Criterion": [{
      "Field": "OrderNo",
      "Operator": "isnotnull",
      "Value": "1"
    }, {
      "Field": "OrderNo",
      "Operator": "<>",
      "Value": "0"
    }],
    "Sort": [
      {
        "Field": "OrderNo",
        "Order": "ASC"
      }
    ],
    "Page": page,
    "PageSize": pageSize,
  }
}
export const getQueuedAssessmentTableListCountBody = () => {
  return {
    intent: '',
    fields: ['CountOfAssessment'],
    criterion: [{
      "Field": "OrderNo",
      "Operator": "isnotnull",
      "Value": "1"
    }, {
      "Field": "OrderNo",
      "Operator": "<>",
      "Value": "0"
    }],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getQueuedAssessmentsModalSearchBody = () => {
  return {
    "Intent": "",
    "Fields": [
      "OrderNo",
      "QueuedAssessment",
      "VendorName",
      "ProductId",
      "ProductName",
      "ProductCategoryName",
      "AssessmentType",
      "ClientId",
      "AssessmentId",
      "VendorId",
      "Source"
    ],
    "Criterion": [{
      "Field": "OrderNo",
      "Operator": "isnotnull",
      "Value": "1"
    }, {
      "Field": "OrderNo",
      "Operator": "<>",
      "Value": "0"
    }],
    "Sort": [
      {
        "Field": "OrderNo",
        "Order": "ASC"
      }
    ],
    "Page": 0,
    "PageSize": 0,
  }
}

export const getAssessmentSearchBody = (id) => {
  return {
    "Intent": "",
    "Fields": [
      "AssessmentId",
      "Source",
      "DisplayExecutiveSummaryLink",
      "LatestAssessmentActionLog"
    ],
    "Criterion": [
      {
        "Field": "AssessmentId",
        "Operator": "=",
        "Value": id
      }
    ],
    "Sort": [],
    "Page": 0,
    "PageSize": 0,
  }
}

export const getAssessmentDetailsByActiveStatusBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      "Field": "AssessmentId",
      "Operator": "=",
      "Value": assessmentId
    })
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'LatestAssessmentActionLog',
      'IsEscalated'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentDetailsByRemediationStatusBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      "Field": "AssessmentId",
      "Operator": "=",
      "Value": assessmentId
    })
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'LatestAssessmentActionLog',
      'IsEscalated'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentDetailsByCompletedStatusBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      "Field": "AssessmentId",
      "Operator": "=",
      "Value": assessmentId
    })
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'CountOfCompletedAssessments',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'IsEscalated',
      'AssessmentLabel',
      'ClientAssessment'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
export const searchAdditionalNotesBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  return {
    intent: '',
    fields: [
      "AssessmentAdditionalNoteId",
      "AssessmentId",
      "Comment",
      "InsertUserId",
      "InsertTimestamp",
      "FirstName",
      "LastName"
    ],
    Criterion: criterion,
    sort: [{
      Field: 'InsertTimestamp',
      Order: 'DESC'
    }],
    page: 0,
    pageSize: 0,
  };
}

export const getAssessmentQuestionnaireRequirementSearchBody = (assessmentQuestionnaireId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementName',
      'AssessmentId',
      'QuestionnaireTemplateId',
      'UpdateTimestamp',
      'CountOfMandatoryAnswers',
      'CountOfMandatoryQuestions',
      'CountOfAnswers',
      'CountOfQuestions',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'RequirementNumber',
      'AssessmentName',
      'AssessmentStatus',
      'RequirementStatus',
      'IsMet',
      'LastUpdated',
      'AssessmentQuestionnaireRequirementId',
      'DynRequirementId',
      'RequirementMetUnMetStatus',
      'ExternalTicketId',
      'Adequacy',
      'IsSystem',
      'IsValidated',
    ],
    Criterion: [
      {
        Field: 'AssessmentQuestionnaireId',
        Operator: '=',
        Value: assessmentQuestionnaireId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentQuestionnaireRequirementGroupSearchBody = (assessmentQuestionnaireId = null) => {

  let criterion = [];
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    Intent: '',
    Fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementStatus',
      'RequirementGroup',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentQuestionnaireProgressSearchBody = (assessmentId, assessmentQuestionnaireId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    },
      {
        "field": "ParentQuestionId",
        "operator": "=",
        "value": NO_VALUE
      },
      {
        "field": "QuestionResponseCategoryId",
        "operator": "=",
        "value": ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID
      }
    );
  }
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }
  return {
    "intent": "",
    "fields": [
      "AssessmentQuestionnaireId",
      "AssessmentQuestionnaireQuestionResponseId",
      "AssessmentQuestionnaireQuestionResponseLogId",
      "ResponseDate",
      "AssessmentQuestionnaireProgress",
      "AssessmentQuestionnaireProgressDateWiseList",
      "QuestionnaireTemplateName"
    ],
    "Criterion": criterion,
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
}


export const getAssessmentFileUploadBody = (assessmentId, uploadedBy) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  if (!isNil(uploadedBy)) {
    criterion.push({
      Field: 'UploadedBy',
      Operator: 'in',
      Value: uploadedBy,
    });
  }

  return {
    "intent": "",
    "fields": [
      "FileName",
      "UploadedBy",
      "UploadDate",
      "Description"
    ],
    "Criterion": criterion,
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
}